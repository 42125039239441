#programas {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

#programas h1{
  font-size: 3vw;
  font-weight: bolder;
  margin: 2vw 0;
}

/*SHOWCASE*/

#prog_showcase{
    background-color: #49448B;
    padding: 2% 10%;
    text-align: left;
    color: #f2f2f2;
    display: flex;
    align-items: center;
  }

#prog_showcase img{
  width: 18%;
  margin: 1%;
}

#prog_title {
  width: 78%;
  margin: 1%;
}

#prog_title h2{
  font-weight: bolder;
  font-size: 2vw;
  color: #f6b911;
}

#prog_title p{
    font-size: 1.5vw;
    color: #f2f2f2;
    text-align: justify;
    margin: 1vw 0;
}

/*P BLOCKS*/

  .programas_block {
      padding: 0 5%;
      margin: 0;
   }

   .programas_block ul{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: nowrap; /* assumes you only want one row */
    padding: 0;
    list-style: none;
   }

  .programas_block li{
    text-align: center;
    background: linear-gradient(180deg, #49448B 0%, #2A2356 100%);
    border-radius: 3vw;
    padding: 2%;
    padding-bottom: 4%;
      margin: 1%;
  }

  .programas_block img {
    width: 12vw;
  }

  .programas_block p {
    margin: 0;
    color: #f2f2f2;
    font-size: 1.5vw;
  }

  .programas_block span {
    color: #f6b911;
  }

  .programas_block li:hover{
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
  }

/*P ROLS*/

  #prog{
    background-color: #49448B;
    color: #f2f2f2;
    padding: 5% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    }

  #prog h1{
    margin-top: 0;
  }

  #prog div{
    font-size: 1vw;
    margin: 0;
    width: 100%;
    }

  #prog ul{
    margin: 0;
  }

  #prog h2{
    color: #f6b911;
    font-weight: bolder;
    font-size: 2vw;
  }


  #prog_left div, #prog_right div{
    width: 55%;

  }

  #prog_left {
    padding-bottom: 1vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

    #prog_figa {
  transform: scaleX(-1); /* Flip horizontally */
    position: absolute;
    right: 0;
    width: 40vw;
    height: 20vw;
    padding: 2% 0%;
  }

  #prog_fig1 {
    position: absolute;
    right: 15%;
    margin: 5% 0;
    width: 10vw;
    height: 10vw;
  }


  #prog_right {
    padding-top: 1vw;
    border-top: 0.4vw solid #F29100;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  #prog_figb {
    position: absolute;
    left: 0;
    width: 40vw;
    height: 20vw;
    padding: 2% 0%;
  }

  #prog_fig2 {
    position: absolute;
    left: 15%;
    margin: 5% 0;
    width: 10vw;
    height: 10vw;
  }


/*P IMPACT*/
  #prog_gallery{
    width: 80%;
    margin-bottom: 2vw;
  }

  #prog_gallery ul{
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin: 0;
    padding: 0;
   }

  #prog_gallery img{
    padding: 1%;
    width:  15vw;
      height: 15vw;
      object-fit: cover;
   }

  .impacto{
  padding: 0 5%;
    color: #2A235C;
    text-align: center;
    font-size: 1.5vw;
    display: flex; /* Use flexbox */
    justify-content: space-between; /* Distribute items equally with space in between */
    align-items: center; /* Center vertically within .impacto */
  }

    .impacto img{
  width: 17vw;
  }

  .expanded-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.expanded-image {
  max-width: 80%; /* Adjust based on your preference */
  max-height: 80%; /* Adjust based on your preference */
  border-radius: 10px; /* Optional, for styled corners */
}

  @media screen and (max-width: 1100px) {

    #prog_showcase img {
    display: none;
    }

    #prog_showcase p {
      font-size: 2.5vw;
    }

    #prog_title{
      width: 90%;
    }

    #prog_title h2 {
      font-size: 3vw;
    }

    #prog {
    padding: 5% 10%;
  }

    #prog h2{
    font-size: 3vw;
    }

    #prog div{
      font-size: 2.5vw;
    }

    .programas_block ul{
      display: block;
    }
    .programas_block li{
      margin: 2% 5%;
      display: flex;
      align-items: center;
      padding-bottom: 2%;
    }
    .programas_block p{
      font-size: 2.5vw;
      width: 80%;
    }

    #prog_left div{
      width: 100%;
    }

    #prog_right div{
      width: 100%;
    }

    #prog img {
      display: none;
    }

    .impacto{
      font-size: 2.5vw;
      width: 90%;
    }

    }
