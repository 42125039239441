#companies {
  width: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vw;
}
.companies-but{
  background-color: transparent;
  font-size: 1.5vw;
  font-weight: 100;
}

#companies h1, #companies h2, .container, .main_slider {
  margin: 1vw 0;
}

#companies h1, #companies h2{
  font-weight: bolder;
}

  #companies h1{
  font-size: 3vw;
}

#companies h2{
  font-size: 1.5vw;
}

#companies ul{
  list-style: none;
}

.main_slider{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

.main_slider img{
  width: 13vw;
}

.container{
  max-width: 100%;
  width: 80%;
  border: solid;
  border-radius: 5px;
}

.slide {
  display: flex !important;
  margin: 0 20px;
}

.slick-track{
  display: flex !important;
  align-items: center;
}

.slide img{
  width: 10vw;
}

/* MOBILE */
@media screen and (max-width: 1100px) {
  #companies h1{
  font-size: 4vw;
}

#companies h2{
  font-size: 2.5vw;
}


}