
/*CONVOCATORIAS*/
  #conv_pred{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px;
  }

  #conv_pred .cconvid {
    width: 100%;
    padding: 10px;
    position: relative;
    margin-bottom: 10px;
}


  #conv_pred span, #conv_pred h5{
    font-weight: bold;
  }

  #conv_pred h5{
    background-color: yellow;
    padding: 10px;
    width: 150px;
    border-radius: 10px;
    text-align: center;
  }

  #conv_pred p{
    text-align: justify;
  }

  #conv_pred .conv-logos{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  }

  #conv_pred .conv-logos img {
    margin: 0;
    width: 150px;
    border-radius: 5px;
    margin: 10px;
  }

  #conv_pred .convnone{
    margin-bottom: 10px;
    width: 100%;
    max-width: 700px;
    
  }

  #conv_pred .n4{
    max-width: 700px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 1100px) {
    
    #conv_pred .cconvid h2 {
      width: 65vw;
    }

    #conv_pred .conv-logos img {
      width: 100px;
    }
  }
