#nosotras {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 94%;
  margin: 1vw 3vw;
}

#nosotras h1{
  font-size: 3vw;
  font-weight: bolder;
  margin: 2vw 0;
}

#main_nosotras{
    font-size: 1.5vw;
    color: #2A235C;
    text-align: justify;
    display: flex;
  }

  #nosotras_left {
    width: 50%;
  }


  #nosotras_left p{
    padding-right: 10%;
  }

   #nosotras_right{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-left: 4px solid #F29100;
    border-bottom: 4px solid #F29100;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 98% 98%;
  }

   #nosotras_right p{
    font-weight: bold;
    color: #f2f2f2;
    padding: 0 5%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  /*ODS*/

  #nos_ods ul{
    align-items: center;
   }

  #nos_ods li{
    padding: 2%;
   }

  #nos_ods img{
    max-width: 100%;
    height: auto;
   }

  /*TEAM*/

  #nos_team ul{
    align-items: center;
   }

  #nos_team li{
    padding: 2%;
    width: 30vw;
   }

  #nos_team img{
    max-width: 100%;
    height: auto;
   }


  /*DIC*/

  #nos_dic ul{
    align-items: center;
   }

  #nos_dic li{
    padding: 2%;
   }

  #nos_dic img{
    max-width: 100%;
    height: auto;
   }

  /*MOBILE*/

  @media screen and (max-width: 1100px) {

    #nosotras h1{
      font-size: 4vw;
    }

    #nosotras_left p{
      font-size: 2.5vw;
    padding-right: 0%;
  }

  #main_nosotras{
    font-size: 2.5vw;
    flex-direction: column;
  }

  #nosotras_left, #nosotras_right {
    width: 100%;
  }

  #nosotras_right {
    height: 65vw;
  }

  }
