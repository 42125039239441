#rrss{
 display: flex;
 align-items: center;
 flex-direction: column;
 width: 98%;
 margin: 1%;
}

.rrss{
    cursor: pointer;
}

#rrss h1{
  margin: 1vw;
  font-size: 3vw;
  font-weight: bold;
}

#rrss_block{
  display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 1vw;
    width: 98%;
}

.card{
  width: 20vw;
}

.card-title{
  font-size: 1vw;
  border-radius: 5px !important;
}

.card-img-top{
  width: 100%;
  height: 20vw;
  object-fit: cover;
}

.card-text{
  width: 18vw;
  height: 16vw;
  overflow: hidden;
}

.card-rrss{
margin-top: 1rem;
overflow: auto;
}

.btn{
  border-color: grey;
  background: linear-gradient(180deg, #49448B 0%, #2A2356 100%);
}

.expanded-card-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.expanded-card-content {
  background: white;
  padding: 1vw;
  border-radius: 1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
}

.expanded-card-image {
  width: 100%;
    border-radius: 1vw;
    height: auto;
    max-height: 27vw;
    object-fit: cover;
}

.expanded-card-body {
display: flex;
flex-direction: column;
align-items: flex-start;
height: 27vw;
width: 100%;
text-align: justify;
justify-content: flex-start;
  padding: 0 1vw;
  border-radius: 1vw;
}

@media screen and (max-width: 1100px) {

  .rrss3, .rrss4{

}

  #rrss h1{
    font-size: 4vw;
  }

  #rrss_block {

}

  .card {
    width: 48%;
    margin: 1%;
}

  .card-img-top {
    height: 45vw;
}

#rrss .card-img-top {
    height: 20vw;
}

.card-title {
    font-size: 3vw;
    border-radius: 5px !important;
}

  .card-text {
    width: 100%;
    height: 25vw;
    overflow: hidden;
}

.expanded-card-content {
    flex-direction: column;
    width: 70%;
    padding: 2.5vw;
}

.expanded-card-image {
    margin-bottom: 1rem;
    max-height: 68vw;
}

.expanded-card-body {
    height: 70vw;
    padding: 0;
}

}