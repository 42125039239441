#statistics{
  color: #49448B;
font-size: 3vw;
padding: 0;
margin: 0;
}

#statistics ul{
list-style-type: none; /*to remove bullets*/
display: flex;
flex-direction: row;
justify-content: space-around;
flex-wrap: nowrap; /* assumes you only want one row */
align-items: flex-start;
text-align: center;
padding: 0;
margin: 1%;
}

#statistics li{
display: flex;
flex-direction: column;
padding: 1%;
text-align: center;  
}

#statistics p{
font-weight: bold;
font-size: 3.5vw;
margin: 0;
}

#statistics span{
color: #f6b911;
}

#statistics li p:last-child {
font-size: 1.5vw;
}

.st_p{
  white-space: nowrap;
}

/* MOBILE */
@media screen and (max-width: 1100px) {
#statistics p {
font-size: 5.5vw;
}

#statistics li p:last-child {
font-size: 2.5vw;
}
}
