html, body {
  font-family: 'Montserrat', sans-serif;
}

body.safari {
    font-family: "Helvetica", sans-serif;
  }

a:link {
      color: #f2f2f2;
      text-decoration: none;
      cursor: pointer;
}

a:visited {
      color: #f2f2f2;
      text-decoration: none;
      cursor: pointer;
}

a:hover {
  color: #F6B911;
      text-decoration: none;
      cursor: pointer;
}

a:active {
  color: #f2f2f2;
  border-radius: 5px;
      text-decoration: none;
      cursor: pointer;
}

button{
  border-width: 0;
  border-radius: 1vw;
  font-size: 2vw;
padding: 1vw 3vw;
  font-weight: bold;
  text-align: center;
}

.yellowbut{
background: #F6B911;
color: #2A235C;
}

.purplebut{
  background: #49448B;
color: #f2f2f2;

}