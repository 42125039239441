#prog_banner{
	width: 100%;
	height: 25vw;
	overflow: hidden;
	background: linear-gradient(180deg, #49448B 0%, #2A2356 100%);
	position: relative;
}

#prog_banner img{
    position: absolute;
	width: 100%;
	z-index:1;
}

.prog_ban_text{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	z-index:2;
	position: absolute;
	left: 10%;
	bottom:10%;
}

.prog_ban_text p{
    background: #49448B;
    padding:0.5vw 1vw;
    opacity:0.95;
    border-radius:1vw;
    color: #f2f2f2;
	font-size: 2vw;
	font-weight: bold;
}

.prog_ban_text a{
    width: 30%;
    text-align: center;
    opacity: 0.95;
    border-radius:1vw;
    background: #F6B911;
     padding:0.5vw 1vw;
     font-size: 1.5vw;
     color: #49448B;
   }

.prog_ban_text a:hover{
    opacity: 1;
    font-weight: bold;
    }

.prog_ban_text span{

}

/* MOBILE */
@media screen and (max-width: 1100px) {

    .prog_ban_text p{
    margin-bottom: 1vw;
    }

}


@media screen and (max-width: 523px) {


}
