#objetivos {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 94%;
  margin: 1vw 3vw;
}

#objetivos h2{
  font-size: 1.5vw;
  font-weight: bolder;
  margin: 2vw 0;
}


#nos_ods ul{
  width: 100%;
  display: flex;
  list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
   }

  #nos_ods li{
    padding: 2%;
   }

  #nos_ods img{
    max-width: 100%;
    height: auto;
   }

  /*MOBILE*/

  @media screen and (max-width: 1100px) {
    #objetivos h2{
      font-size: 2.5vw;
    }
  }
