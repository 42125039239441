.news {
  display: flex;
  flex-direction: column;
  margin: 2% 5%;
  border: 1px solid rgba(0, 0, 0, 0.125); /* Light border similar to Bootstrap's card */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px;
  background-color: #fff;
}

.news h2{
  width: 90%;
  margin-bottom: 15px;
  text-align: left;
}

.news .date {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: inline-block;
  font-size: 14px;
}


/* Make the image float to the right */
.news-image {
  float: right;
  width: 400px; /* Adjust as needed */
  height: 400px;
  margin-left: 20px;
  margin-bottom: 10px;
  border-radius: 0.25rem;
}

/* Ensures image fills the right-side area */
.news-image img {
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  object-fit: cover;
    object-position: left;
}

/* Content area */
.news-content {
  overflow: hidden; /* Ensures content wraps around the floating image */
  text-align: justify;
  font-size: 14px;
}

.news .link{
  cursor: pointer;
}

.news .volver button{
  font-size: 14px;
}

@media screen and (max-width: 750px) {
  .news h2{
  font-size: 18px;
}
    .news-image {
    float: none;
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-bottom: 10px;
  }
}