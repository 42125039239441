#novedades {
  display: flex;
  flex-direction: column;
  margin: 2% 5%;
  border: 1px solid rgba(0, 0, 0, 0.125); /* Light border similar to Bootstrap's card */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px;
  background-color: #fff;
}

.loading-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  font-size: 1.5em;
  color: #333;
}

#novedades .filters{
  width: 100%;
    margin: 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #49448B 0%, #2A2356 100%);
    padding: 10px;
    border-radius: 10px;
    justify-content: space-around;
    align-items: center;
}

#novedades .filters input{
  font-size: 12px;
  width: 100%;
  height: 30px;
  margin-bottom: 5px;
  border-radius: 5px;
}

#novedades .filters select{
  font-size: 12px;
  width: 100% !important;
  margin-bottom: 5px;
  border-radius: 5px;
}

#novedades .filters button{
  font-size: 12px;
  width: 100%;
  margin-bottom: 5px;
  border-radius: 5px;
}

#novedades .year-selector{
  width: 100%;
  height: 30px;
  margin-bottom: 5px;
}

#novedades .year-dropdown{
  width: 100%;
  height: 30px;
}

#novedades .novedades{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#novedades .novedades .novedades-item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  width: 243px;
  border: 1px solid rgba(0, 0, 0, 0.125); /* Light border similar to Bootstrap's card */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px;
  background-color: #fff;
}

#novedades .noticia{
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

#novedades .testimonio{
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: left;
  border-radius: 10px;
}

#novedades .date {
  margin:0;
  margin-top: 5px;
  display: inline-block;
  font-size: 14px;
}

@media screen and (max-width: 1100px) {
  

}

