#directorio {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 94%;
  margin: 1vw 3vw;
}

#directorio h1{
  font-size: 3vw;
  font-weight: bolder;
  margin: 2vw 0;
}

#nos_dic ul{
    align-items: center;
    display: flex;
    list-style: none;
    padding: 0;
   }

  #nos_dic li{
    padding: 2%;

   }

  #nos_dic img{
    max-width: 100%;
    height: auto;
     border-radius: 5px;
   }
