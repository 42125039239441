#main_banner {
width: 100%;
background: linear-gradient(180deg, #49448B 0%, #2A2356 100%);
}

#main_banner .slide{
    margin:0;
}

#main_banner .carousel-control-prev{
    justify-content: flex-start;
    margin-left:5px;
}

#main_banner .carousel-control-next{
    justify-content: flex-end;
    margin-right:5px;
}

#main_banner .carousel-indicators button{
    height: 10px;
}

/* MOBILE */
@media screen and (max-width: 1100px) {

#main_banner .carousel-control-prev{
    margin-left:0px;
}

#main_banner .carousel-control-next{
    margin-right:0px;
}

#main_banner .carousel-indicators button{
    height: auto;
}

}