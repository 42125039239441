/*SUBSCRIBE*/

    #subscribe{
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 1% 0;
    }

    #subscribe h1{
      margin: 1vw;
    font-size: 3vw;
    font-weight: bold;
}

    #subscribes {
      display: flex;
      width: 100%;
      }

    #subscribes div{
      flex: 50%;
      display: flex;
      flex-direction:column;
      align-items: center;
      justify-content: space-around;
    }

    #subscribes ul{
      list-style-type: none; /*to remove bullets*/
      height: 100%;
        width: 70%;
        padding: 0;
        margin: 4%;
        display: flex;
        flex-direction: column;
     }

    #subscribes li{
      flex:33%;
      padding: 1%;
      text-align: center;
     }

    #subscribes li img{
      overflow: hidden;
      height: 10vw;
      width: auto;
      margin:-7%;
     }

    #subscribes li p{
      margin: 0;
      font-weight: bold;
      font-size: 2.5vw;
     }

    #subscribes li button{
      margin-top: 4%;
      font-size: 2vw;
      width: 70%;
     }

    /* MOBILE */
    @media screen and (max-width: 1100px) {
        #subscribe h1 {
            font-size: 4vw;
        }
    }

/*BOXES*/

    #leftbox  {background: linear-gradient(180deg, #49448B 0%, #2A2356 100%);}  
    #leftbox  p {color:#f2f2f2;}
    #leftbox  span{color:#F6B911;font-weight: bold;}

    #rightbox {background: linear-gradient(180deg, #F6EC78 0%, #F6B911 100%);}
    #rightbox p{color:#2A235C;}
    #rightbox span{color:#81358A;font-weight: bold;}