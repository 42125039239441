#equipo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 94%;
  margin: 1vw 3vw;
}

#equipo h1{
 font-size: 3vw;
  font-weight: bolder;
  margin: 2vw 0;
}

#nos_team ul{
  display: flex;
  list-style: none;
    align-items: center;
    padding: 0;
   }

  #nos_team img{
    width: 100%;
    border-radius: 5px;
   }

/*MOBILE*/

  @media screen and (max-width: 1100px) {
    #equipo h1{
 font-size: 4vw;
}
  }
