#linktree {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%%;
  padding: 0% 25%;
  padding-bottom: 5%;
  background: linear-gradient(180deg, #49448B 0%, #2A2356 100%);
}

.lt-logo {
  width: 60%;
}

.lt-logo img {
  width: 100%;
}

.lt-a{
  display: flex;
  width: 100%;
  align-items: center;
  height: auto;
  border: solid #f2f2f2;
  border-radius: 80px;
  text-decoration: none;
  color: #f2f2f2;
  margin: 1vw 0;
}

.lt-a:active{
  display: flex;
  width: 100%;
  align-items: center;
  height: auto;
  border: solid #f2f2f2;
  border-radius: 80px;
  text-decoration: none;
  color: #f2f2f2;
  margin: 1vw 0;
}

.lt-img{
      width: 7.5vw;
    height: 7.5vw;
    margin-left: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
    border-radius: 50%;
    object-fit: cover;
    border: solid #81358A;
}

.lt-h{
  width: 70%;
  text-align: center;
  font-size: 2vw;
  margin:2% 5%;
}

.lt-test{
  object-position: left;
}

.lt-ico{
  object-fit: scale-down;
  border: none;
  border-radius: 0;

}

/* MOBILE */
@media screen and (max-width: 1100px) {
  #linktree {
  width: 100%%;
  padding: 0% 5%;
  padding-bottom: 5vw;
}

.lt-h{
  font-size: 3vw;
}

.lt-img {
  width: 20vw;
  height: 20vw;
}


}
