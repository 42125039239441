/* Header Styles */
.header {
  top: 0;
  z-index: 1000;
  overflow: visible;
  background: linear-gradient(180deg, #49448B 0%, #2A2356 100%);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.dropdown-item{
  color: #1D1D1B !important;
}

.dropdown-item:hover {
  background: #F6B911 !important;
  border-radius: 5px;
}

.dropdown-item:active {
  background: #F6B911 !important;
  border-radius: 5px;
}

@keyframes rainbowGlow {
  0% { box-shadow: 0 0 5px 2px red; }
  14% { box-shadow: 0 0 5px 2px orange; }
  28% { box-shadow: 0 0 5px 2px yellow; }
  42% { box-shadow: 0 0 5px 2px green; }
  57% { box-shadow: 0 0 5px 2px blue; }
  71% { box-shadow: 0 0 5px 2px indigo; }
  85% { box-shadow: 0 0 5px 2px violet; }
  100% { box-shadow: 0 0 5px 2px red; }
}

.socio {
  animation: rainbowGlow 2s infinite linear;
  border-radius: 5px;
  padding: 5px;
}

.logo {
  padding: 0 15px 0 75px;
  display: flex;
  align-items: center;
}

.logo img {
  height: 7vw;
  width: auto;
}

nav {
  margin: 0 75px 0 auto;
  float: right;
  padding: 0 15px;
  font-size: 1.2vw;
}

nav ul {
  margin: 1em 0;
  padding-inline-start: 0;
}

nav li {
  display: inline-block;
  padding: 5px 20px;
}

/* Sidebar Styles */
.sidebar {
  display: none;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #2A235C;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #f2f2f2 !important;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #F6B911 !important;
}

.nav-link:hover{
  color: #F6B911 !important;
}

.nav-link:focus{
  color: #F6B911 !important;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: white;
  background: none;
}

/* Button Styles */
.openbtn {
  display: none;
  font-size: 20px;
  cursor: pointer;
  background: linear-gradient(180deg, #81358A 0%, #49448B 100%);
  color: #f2f2f2;
  padding: 10px 15px;
  border: none;
  margin: 10px;
}

.openbtn:hover {
  background-color: #1D1D1B;
}

/* Mobile Styles */
@media screen and (max-width: 1100px) {
  .header {
  position: sticky;
  }

  nav {
    display: none;
  }

  .logo{
    margin-left: 10px;
  }

  #resnav button {
    display: inline-block;
  }

  #mySidebar {
    display: block;
  }

  .logo {
    padding: 0;
  }

  .logo img {
    height: 70px;
  }
}